@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

html {
  background-color: rgb(46,46,46);
  color: white;
  font-family: 'Roboto', sans-serif;
}

#header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: none;
  font-size: 20px;
}

.footer {
  border-top: 1px solid white;
}

#header a#button {
  text-decoration: none;
  color: white;
}

#header #links ul li a span {
  color: rgb(180,180,180);
}

#header p:first-child {
  font-size: 30px;
}

#links ul li {
  display: inline-block;
  margin: auto 20px;
}

#links ul li, #links ul li a {
  text-decoration: none;
}

#links ul {
  padding-left: 0px;
}

#links ul li a:visited {
  color:white;
}

#links ul li a:active {
  color: darkgray;
}

#button {
  background-color: rgb(0,85,165);
  border-radius:30px;
  padding: 12px 20px;
  transition: 0.3s ease;
}

#button:hover {
  cursor: pointer;
  background-color: rgb(0,119,234);
}

#splash {
  background-image: url("./resources/waterfall_edit.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 100px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

#splash h1, #splash h2 {
  margin: 0px 20px;
}

#splash h1 {
  font-size: 72px;
  padding-top: 40px;
}

#splash h2 {
  font-size: 40px;
}

#splash img {
  height: 30vh;
  border: 6px white solid;
  border-radius: 300px;
  float: left;
  min-height: 240px;
}

.empty_space {
  margin: 0px 50px;
}

h1.header {
  font-size: 48px;
  text-align: center;
}

#about {
  margin: 20px 25%;
  text-align: center;
}

.info {
  font-size: 22px;
}

div.project {
  text-align: center;
  margin-bottom: 100px;
}

div.project a {
  font-size: 40px;
}

div.project a:link, div.project a:visited {
  color: rgb(0, 102, 255);
}

div.project a:active {
  color: rgb(63, 138, 252);
}

#portfolio {
  margin: 100px 10% 0 10%;
}

.project-info {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.project-info img {
  width: 700px;
  border: 4px solid gray;
  border-radius: 25px;
  margin-right: 40px;
}